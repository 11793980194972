import React, { useState, useCallback, useRef, useEffect, useContext } from 'react';
import { Container } from 'react-bootstrap';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';

import * as Constants from '../../helpers/constants';
import UserActivitiesTable from './user_activities_table';
import { ApiHelper } from '../../helpers/apihelper';
import AppConfigContext from '../dashboard/app_config_context'

import '../../static/css/userlist.css';

const initialState = {
  activities_list: { nodes: [] },
  tableDataLength: 1,
  searchText: '',
  sort: {
    'created_on': 'desc'
  },
  name: '',
  email: ''
};


const UserActivities = () => {
  const [state, setState] = useState(initialState);
  const abortControllerRef = useRef(null); // Reference to store the AbortController
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const user_id = queryParams.get('user_id');
  console.log(state.name, state.email)

  const appConfig = useContext(AppConfigContext)
  const client = appConfig.client

  const getUserActivities = useCallback((skip, limit, searchText) => {

    // Abort any ongoing request before making a new one
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new AbortController for the new request
    const controller = new AbortController();
    abortControllerRef.current = controller;

    let requestBody = {
      co_user_id: user_id,
      skip: skip,
      limit: limit,
      search: searchText,
      sort: state.sort,
      filters: {},
      client_secret: Constants.CLIENT_SECRET,
    };
    let body = _.pick(requestBody, ApiHelper(client, 'USER_ACTIVITY').API_BODY)

    ApiHelper(client, 'USER_ACTIVITY').API(client, body, abortControllerRef.current.signal)
      .then((res) => {
        if (res.status === Constants.CO_API.SUCCESS) {
          const updatedData = res.data.data.map(v => {
            v.id = v.id || _.uniqueId('abuse_');
            return v;
          });

          setState(prevState => ({
            ...prevState,
            activities_list: { nodes: updatedData },
            tableDataLength: updatedData.length,
            name: res.data.name,
            email: res.data.email
          }));
        } else {
          console.log('Failed to get user activities');
        }
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          console.log(err);
        }
      });
  }, [user_id, state.sort, client]);

  // Handle component unmounting to abort ongoing requests
  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  return (
    <Container fluid>
      {client ? (
        <h1 className='header'>{`${state.name || state.email}'s - User Activities`}</h1>
      ) : <h1 className='header'>{client} Users Activities</h1>}
      <div className='pt-3'>
        <UserActivitiesTable
          getUserActivities={getUserActivities}
          data={state.activities_list}
        />
      </div>
    </Container>
  );
};

export default UserActivities;
